import MD5 from "crypto-js/md5";

import Logger from "../components/Logger/Logger";

export const extractParams = (url) => {
	var params = {};
	var s = url.indexOf('#/');
	if (s !== -1) {
		var arr = url.substr(s + 2).split('/');
		var st = 0;
		if (arr.length % 2 !== 0) {
			st = 1;
		}
		for (var i = st; i < arr.length; i += 2) {
			params[arr[i]] = arr[i + 1];
		}
	}
	return params;
}

export const parseHashParams = (tmpArr) => {
	let l = 0;
	let res = {};
	for (var i = 0; i < tmpArr.length; i += 2) {
		res[tmpArr[i]] = tmpArr[i + 1];
		l++;
	}
	res.length = l;
	return res;
}

export const parseQSParams = (str) => {
	let l = 0;
	let res = {};
	let arrQS = str.split('&');
	for (let i = 0; i < arrQS.length; i++) {
		let t = arrQS[i].split('=');
		res[t[0]] = t[1];
		l++;
	}
	res.length = l;
	return res;
}

export const parseHash = (cr) => {
	let res = {};
	let hashbangs;
	if (cr.indexOf('&') !== -1) {
		hashbangs = [];
		hashbangs[0] = cr;
	} else {
		hashbangs = cr.split('/');
	}
	if (hashbangs.length === 1) {
		// possible RedirectURL
		if (hashbangs[0].indexOf('&') !== -1) {
			res.params = parseQSParams(hashbangs[0]);
			if (res.params.hasOwnProperty('state')) {
				res.controller = 'RedirectURL';
			}
		} else {
			// there must be an error, redirect to ? home ? error page ?
			res.controller = 'InvalidURL';
		}
	} else {
		if (hashbangs.length % 2 !== 0) {
			// odd --> there is a controller followed by several params
			res.controller = hashbangs[0];
			res.params = parseHashParams(hashbangs.slice('1'));
		} else {
			res.controller = '';
			res.params = parseHashParams(hashbangs);
		}
	}
	return res;
}

export const ST = (o, separator, filter) => {
	let that = this;
	let s = '-';
	var o = {};
	if (typeof separator !== "undefined") s = separator;
	if (typeof o !== 'object') {
		return s + String(o);
	} else {
		var f = false;
		if (o !== null) {
			if (o.hasOwnProperty('filter') && o.filter) {
				f = true;
			}
			var a = false;
			if (o.hasOwnProperty('assert') && o.assert) {
				a = true;
			}
		}
		var str = '';
		for (var i in o) {
			if (i === 'filter' || i === 'assert') {
				str += '';
			} else if (typeof filter !== 'undefined') {
				if (!isNaN(o[i])) {
					str += that.ST(o[i], undefined, true);
				}
			} else if (a) {
				if (typeof o[i] !== undefined) {
					str += s + 'true';
				} else {
					str += s + 'false';
				}
			} else if (f) {
				str += that.ST(o[i], undefined, true);
			} else {
				if (typeof o[i] !== 'function') str += that.ST(o[i]);
			}
		}
		return str;
	}
}

export const getBrowserFingerPrint = () => {
	let x, str = "";
	let n = navigator;
	try {
		x = [n.appCodeName, n.appName, n.cookieEnabled, n.hardwareConcurrency, n.language, n.languages, n.maxTouchPoints, n.platform, n.product, n.productSub, n.userAgent, n.vendor, {
			prop: window.sessionStorage,
			assert: true
		}, {
			prop: window.localStorage,
			assert: true
		}, {
			prop: screen,
			filter: true
		}, (new Date()).getTimezoneOffset()];
		str = ST(x);
		return MD5(str).toString();
	} catch (e) {
		console.log(e);
	}
}

export const getBrowserLanguage = () => {
	let l = ((navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage);
	if (l) {
		if (l.length == 2) {
			l = process.env.REACT_APP_DEFAULT_LANGUAGE;
		} else {
			l = l.replace("-", "_");
		}
	}
	return l;
}

export const getAuthorizeURL = (trx) => {
	let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_AUTHORIZE_ENDPOINT + 'trx/' + trx;

	if( process.env.REACT_APP_REGION_INDICATOR_APIX_ENABLED ){
		let envRegion = window.location.href.split("env/")[1];

		if( envRegion !== undefined ){
			if( envRegion.split('/').length > 0 ){
				envRegion = envRegion.split('/')[0];
			}
			if( envRegion !== undefined && envRegion !== '' ){
				url = url + '/env/' + envRegion;
			}
		}
	}

	return url;
}

export const getUrl = () => {
	let qs = window.location.search;
	let cr = window.location.hash;

	let obj = {};

	if (qs !== '') {
		qs = qs.substring(1);
		obj.controller = 'RedirectURLWithError';
		obj.params = parseQSParams(qs);
	} else if (cr !== '' && typeof cr !== 'undefined') {
		cr = cr.substring(1);
		obj = parseHash(cr);
	} else {
		obj.controller = '';
	}

	return obj;
}

export const isIE = () => {
	const ua = window.navigator.userAgent;
	return (ua.indexOf('Trident/') > -1 || ua.indexOf("MSIE") > -1);
}

export const isLocalStorage = () => {
	let test = 'test';
	try {
		localStorage.setItem(test, test);
		localStorage.removeItem(test);
		return true;
	} catch (e) {
		return false;
	}
}

export const trackStatus = () => {
	let ts = false;
	
	if( localStorage.getItem('trackStatus') === "Rejected" ){
		ts = false;
	}else if( localStorage.getItem('trackStatus') === "Accepted" ){
		ts = true;
	}

	return ts;
}

export const uuid = () => {
	let d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += performance.now();
	}
	let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		let r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
	return uuid;
}

export const logStatus = (response, contextName) => {
	if(response.links && response.links['urn:vodafoneid:follow']){
		let message = "no status details";
		let url = response.links['urn:vodafoneid:follow'].href;

		if( response.status ){
			if( typeof response.status === 'object' ){
				if( response.status.type ){
					message = response.status.type + ": ";
				}
				if( response.status.msgId ){
					message += response.status.msgId;
				}
			}else{
				message = response.status;
			}
		}

		if((response.status === "SUCCESS") && url.indexOf("code") !== -1 && url.indexOf("state") !== -1){ // TODO: Handle workaround to log a proper message
			Logger.info(contextName + ": Authentication Successful");
		}else if( response.status === "ERROR" ){
			Logger.info(contextName + ": Authentication failed: " + message);
		}
	}
}

export const createRedirectLogoutData = () => {
	let dt = {};
	let responseContext = parseQueryString(window.location.href);
	let url = "";
	if (responseContext && responseContext !== undefined) {
		dt.post_logout_redirect_uri = (responseContext.post_logout_redirect_uri && responseContext.post_logout_redirect_uri !== 'undefined') ? responseContext.post_logout_redirect_uri : '';
		if (dt.post_logout_redirect_uri !== '') {
			url = dt.post_logout_redirect_uri;
		}
		dt.state = (responseContext.state && responseContext.state !== 'undefined') ? responseContext.state : '';
		if (url !== "" && dt.state !== '') {
			url = url + "?state=" + dt.state;
		}
		dt.id_token_hint = (responseContext.id_token_hint && responseContext.id_token_hint !== 'undefined') ? responseContext.id_token_hint : '';
		if (url !== "" && dt.state !== "" && dt.id_token_hint !== '') {
			url = url + "&id_token_hint=" + dt.id_token_hint;
		} else if (url !== "" && dt.id_token_hint !== '') {
			url = url + "?id_token_hint=" + dt.id_token_hint;
		}
		dt.client_id = (responseContext.client_id && responseContext.client_id !== 'undefined') ? responseContext.client_id : '';
		if (url !== "" && dt.state !== "" && dt.id_token_hint !== "" && dt.client_id !== '') {
			url = url + "&client_id=" + dt.client_id;
		} else if (url !== "" && dt.client_id !== '') {
			url = url + "?client_id=" + dt.client_id;
		}
	}
	return decodeURIComponent(url);
}

export const parseQueryString = (url) => {
	let urlParams = {};
	url.replace(
		new RegExp("([^?=&]+)(=([^&]*))?", "g"),
		function($0, $1, $2, $3) {
			urlParams[$1] = $3;
		}
	);
	return urlParams;
}

export const isWebView = () => {
	let standalone = window.navigator.standalone,
	userAgent = window.navigator.userAgent.toLowerCase(),
	safari = /safari/.test(userAgent),
	ios = /iphone|ipod|ipad/.test(userAgent);
	
	let webViewiOS = false;
	let webViewAndroid = false;

	if (ios) {
		if (!standalone && safari) {
			// Safari
			return false;
		} else if (!standalone && !safari) {
			webViewiOS = true;
			return webViewiOS;
		};
	} else {
		if (userAgent.includes('wv')) {
			webViewAndroid = true;
			return webViewAndroid;
		} else {
			// Chrome
			return false;
		}
	};	
}

export const renderAsMinutesAndSeconds = (millis) => {
	let minutes = Math.floor(millis / 60000);
	let seconds = ((millis % 60000) / 1000).toFixed(0);
	// ES6 interpolated literals/template literals 
	// If seconds is less than 10 put a zero in front.
	return `${minutes}:${(seconds < 10 ? "0" : "")}${seconds}`;
}