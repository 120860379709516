import React, { createContext, useReducer } from "react";
import * as actionTypes  from '../constants/actionTypes';

import {
	isLocalStorage,
	trackStatus
} from "../utils/Utils";

import useConsent from "../components/Consent/useConsent";

export const StoreContext = createContext();

const url = new URL(window.location.href);
const urlHash = window.location.hash;
const urlHashString = urlHash.startsWith('#/?') ? urlHash.slice(2) : "";
const urlHashParams = new URLSearchParams(urlHashString);

const cookies = {};

document.cookie.split(';').forEach(cookie => {
	const parts = cookie.split('=');
	const name = parts.shift().trim();
	const value = decodeURIComponent(parts.join('='));
	cookies[name] = value;
});

let consentCookieData = {};
let allowFunctionalCookies = true;

if(cookies["consent"]){
	try {
		consentCookieData = JSON.parse(atob(cookies["consent"]));
	}catch{
		console.error("Error parsing consent cookie:", error);
	}
}

const getTRX = () => {
	let trx = window.location.href.split("trx/")[1]

	if (trx !== undefined && trx.split('/').length > 0) {
		trx = trx.split('/')[0];
	}

	return trx;
}

const checkIfCookieCategoryAllowed = (cookies) => {
	if(cookies){
		for( const [key, value] of Object.entries(cookies) ){
			if( !value ){
				return false;
			}
		}
		return true;
	}else{
		return false;
	}
};

let trackStatusSet = isLocalStorage() === true && localStorage.getItem('trackStatus');

const initialState = {
	brandtmpl:  window.location.pathname !== "/" && window.location.pathname !== "/authorize" && window.location.pathname !== "" ? window.location.pathname.replace(/\//g, "")
				: url.searchParams.get("page_look") ? url.searchParams.get("page_look")
				: urlHashParams.get('page_look') ? urlHashParams.get('page_look')
				: "default",
	trx: getTRX(),
	
	language: "en",
	loading: true,
	
	data: false,
	error: false,

	popup: false,
	banner: true,
	prompt: false,

	msisdnIndex: 0,
	rememberMe: consentCookieData && consentCookieData["functional"] && consentCookieData["functional"]["cookies"] ? checkIfCookieCategoryAllowed(consentCookieData["functional"]["cookies"]) : false,
	tealium: consentCookieData && consentCookieData["marketing"] && consentCookieData["marketing"]["cookies"] ? checkIfCookieCategoryAllowed(consentCookieData["marketing"]["cookies"]) : false,
	smapi: trackStatusSet ? trackStatus() : consentCookieData && consentCookieData["performance"] && consentCookieData["performance"]["cookies"] ? checkIfCookieCategoryAllowed(consentCookieData["performance"]["cookies"]) : false
};

const store = createContext(initialState);
const { Provider } = store;

const initialReducer = (state, action) => {
		switch (action.type) {
			case actionTypes.ERROR:
				return {
					...state,
					language: action.language,
					loading: false,
					error: true
				};
			case actionTypes.LANGUAGE:
				return {
					...state,
					language: action.language,
					loading: false
				};
			case actionTypes.LOADING:
				return {
					...state,
					loading: action.loading ? action.loading : true
				};
			case actionTypes.RESPONSE:
				return {
					...state,
					loading: false,
					data: action.data,
					msisdnIndex: action.msisdnIndex ? action.msisdnIndex : 0
				};
			case actionTypes.POPUP:
				return {
					...state,
					loading: false,
					popup: action.popup,
					banner: false
				};
			case actionTypes.BANNER:
				return {
					...state,
					loading: false,
					popup: false,
					banner: action.banner
				};
			case actionTypes.PROMPT:
				return {
					...state,
					loading: false,
					prompt: action.prompt
				};
			case actionTypes.CDN_CONTENT:
				return {
					...state,
					loading: false,
					tnc: action.tnc,
					pp: action.pp,
				};
			case actionTypes.TNC_CONTENT:
				return {
					...state,
					loading: false,
					tnc: action.tnc,
				};
			case actionTypes.REMEMBER_ME:
				return {
					...state,
					rememberMe: action.rememberMe,
				};
			case actionTypes.PP_CONTENT:
				return {
					...state,
					loading: false,
					pp: action.pp,
				};
			case actionTypes.TEALIUM:
				return {
					...state,
					tealium: action.tealium
				};
			case actionTypes.SMAPI:
				return {
					...state,
					smapi: action.smapi
				};
			default:
				return state;
		}
};

export const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(initialReducer, initialState );
    const value = { state, dispatch };

    return (
        <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
    );
};