import React, {
	useEffect,
	useContext,
    useState
} from "react";

import {
	useCookies
} from 'react-cookie';

import {
    StoreContext
} from "../../store/store";

import axios from "axios";

import { v4 as uuidv4 } from 'uuid';

export const useConsent = (functionalCookiesEnabled, performanceCookiesEnabled, marketingCookiesEnabled) => {
	const globalState = useContext(StoreContext);
	const { dispatch } = globalState;

    const [cookies, setCookie, removeCookie] = useCookies(necessaryCookiesList, functionalCookiesList, performanceCookiesList, marketingCookiesList);
    const uniqueId = uuidv4();

    const necessaryCookiesList =   window.Config.REACT_APP_NECESSARY_COOKIES_LIST.split(',').map(c => c.trim());
    const functionalCookiesList =  window.Config.REACT_APP_FUNCTIONAL_COOKIES_LIST.split(',').map(c => c.trim());
    const performanceCookiesList = window.Config.REACT_APP_PERFORMANCE_COOKIES_LIST.split(',').map(c => c.trim());
    const marketingCookiesList =   window.Config.REACT_APP_MARKETING_COOKIES_LIST.split(',').map(c => c.trim());

    const cookiesList = {
        necessary: {
            cookies: necessaryCookiesList,
            enabled: true
        },
        functional: {
            cookies: functionalCookiesList,
            enabled: functionalCookiesEnabled
        },
        performance: {
            cookies: performanceCookiesList,
            enabled: performanceCookiesEnabled
        },
        marketing: {
            cookies: marketingCookiesList,
            enabled: marketingCookiesEnabled
        }
    }

    // const vfOrgID = "AF0841B5546DC8790A4C98A7%40AdobeOrg";
    // const vfCZOrgID = "3E463FE452A647F00A490D45@AdobeOrg";

    useEffect(() => {
        // setCookie("incap_ses_123123", "test_value_123123");
        // setCookie("nlbi_123123", "test_value_123123");

        return () => {
        };
    }, [])

    const rejectConsent = (category) => {
        if( window.Config.REACT_APP_DEBUG_MODE ){
            console.log("");
            console.log("useConsent: reject " + category + " cookies consent");
        }

        cookiesList[category].cookies.forEach(cookie => {
            let cookieName = cookie;
            let cookiePrefix = "";

            // Check for dynamic cookie name
            if(cookieName.indexOf("*") != -1){
                cookiePrefix = cookieName.replace('*', '');
                
                Object.keys(cookies).forEach((cookieName) => {
                    if (cookieName.startsWith(cookiePrefix)) {
                        if( window.Config.REACT_APP_DEBUG_MODE ){
                            console.log("useConsent: delete " + cookieName + " cookie");
                        }

                        removeCookie(cookieName);
                    }
                });
            }else{
                if( window.Config.REACT_APP_DEBUG_MODE ){
                    console.log("useConsent: delete " + cookieName + " cookie");
                }
            }
            
            removeCookie(cookieName);
        });
    }

    const isConsentValid = () => {
        let consent = getConsent();
        let isConsentValid = true;

        if(consent){
            if( window.Config.REACT_APP_DEBUG_MODE ){
                console.log("useConsent: validate consent");
            }

            for(const [category, categoryCookies] of Object.entries(cookiesList)){                
                if(categoryCookies.enabled){
                    if( window.Config.REACT_APP_DEBUG_MODE ){
                        console.log("  " + category + " ckookies");
                    }

                    if(consent[category]){
                        categoryCookies.cookies.forEach(element => {
                            if(consent[category].cookies.hasOwnProperty(element)){
                                if( window.Config.REACT_APP_DEBUG_MODE ){
                                    console.log("+ " + element);
                                }
                            }else{
                                if( window.Config.REACT_APP_DEBUG_MODE ){
                                    console.log("- " + element + " cookie not found");
                                }
                                isConsentValid = false;
                            }
                        });
                    }else{
                        if( window.Config.REACT_APP_DEBUG_MODE ){
                            console.log("- " + category + " category not found");
                        }
                        isConsentValid = false;
                    }
                }
            }
        }else{
            isConsentValid = false;
        }

        return isConsentValid;
    }

    const getConsent = () => {
        if( window.Config.REACT_APP_DEBUG_MODE ){
            console.log("");
            console.log("——— Consent ————————————");
        }

        let consent = null;

        if(cookies.consent){
            try {
                consent = JSON.parse(atob(cookies.consent))

                if( window.Config.REACT_APP_DEBUG_MODE ){
                    console.log(consent);
                    console.log("");
                }

            } catch (error) {
                console.log("useConsent: parsing consent cookie failed");
                console.log(error)
            }
        }else{
            if( window.Config.REACT_APP_DEBUG_MODE ){
                console.log("useConsent: no consent cookie found");
            }
        }

        return consent;
    }

    const setConsent = (necessaryCookiesAllowed, functionalCookiesAllowed, performanceCookiesAllowed, marketingCookiesAllowed) => {
        if( window.Config.REACT_APP_DEBUG_MODE ){
            console.log("useConsent: set consent");
        }

        let consentCookieDate = new Date();
        let consentCookieDomain = ".vodafone.com";

        if( process.env.REACT_APP_ENV === "local.react" ){
            consentCookieDomain = "localhost";
        }

        let consentCookieDetails = {};

        // necessary
        consentCookieDetails.necessary = {
            "on": consentCookieDate,
            "cookies": {}
        }

        cookiesList.necessary.cookies.forEach(element => {
            consentCookieDetails.necessary.cookies[element] = necessaryCookiesAllowed;
        });
        
        // functional
        if(functionalCookiesEnabled){
            consentCookieDetails.functional = {
                "on": consentCookieDate,
                "cookies": {}
            }

            cookiesList.functional.cookies.forEach(element => {
                consentCookieDetails.functional.cookies[element] = functionalCookiesAllowed;
            });
        }

        // performance
        if(performanceCookiesEnabled){
            consentCookieDetails.performance = {
                "on": consentCookieDate,
                "cookies": {}
            }

            cookiesList.performance.cookies.forEach(element => {
                consentCookieDetails.performance.cookies[element] = performanceCookiesAllowed;
            });
        }

        // marketing
        if(marketingCookiesEnabled){
            consentCookieDetails.marketing = {
                "on": consentCookieDate,
                "cookies": {}
            }

            cookiesList.marketing.cookies.forEach(element => {
                consentCookieDetails.marketing.cookies[element] = marketingCookiesAllowed;
            });
        }

        if(cookies.consent){
            consentCookieDetails = getConsent();

            // necessary
            if(consentCookieDetails.necessary){
                consentCookieDetails.necessary.on = consentCookieDate;
            }else{
                consentCookieDetails.necessary = {
                    "on": consentCookieDate,
                    "cookies": {}
                }
            }
            
            cookiesList.necessary.cookies.forEach(element => {
                consentCookieDetails.necessary.cookies[element] = necessaryCookiesAllowed;
            });

            // functional
            if(functionalCookiesEnabled){
                if(consentCookieDetails.functional){
                    consentCookieDetails.functional.on = consentCookieDate;
                }else{
                    consentCookieDetails.functional = {
                        "on": consentCookieDate,
                        "cookies": {}
                    }
                }

                cookiesList.functional.cookies.forEach(element => {
                    consentCookieDetails.functional.cookies[element] = functionalCookiesAllowed;
                });
            }

            // performance
            if(performanceCookiesEnabled){
                if(consentCookieDetails.performance){
                    consentCookieDetails.performance.on = consentCookieDate;
                }else{
                    consentCookieDetails.performance = {
                        "on": consentCookieDate,
                        "cookies": {}
                    }
                }

                cookiesList.performance.cookies.forEach(element => {
                    consentCookieDetails.performance.cookies[element] = performanceCookiesAllowed;
                });
            }

            // marketing
            if(marketingCookiesEnabled){
                if(consentCookieDetails.marketing){
                    consentCookieDetails.marketing.on = consentCookieDate;
                }else{
                    consentCookieDetails.marketing = {
                        "on": consentCookieDate,
                        "cookies": {}
                    }
                }

                cookiesList.marketing.cookies.forEach(element => {
                    consentCookieDetails.marketing.cookies[element] = marketingCookiesAllowed;
                });
            }
        }

        if( window.Config.REACT_APP_DEBUG_MODE ){
            console.log("useCookies: consent:", consentCookieDetails);
        }

        removeCookie("consent");

        setCookie("consent", btoa(JSON.stringify(consentCookieDetails)), {
            path: "/",
            expires: new Date(new Date().getTime() + 31536000000),
            domain: consentCookieDomain
        });
    }

	return {
        getConsent,
        isConsentValid,
        setConsent,
        rejectConsent
    };
}

export default useConsent;
